import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import './App.css';

//Redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import { logoutUser, getUserData  } from './redux/actions/userActions';

//Components
import Navbar from './components/Navbar';
import AuthRoute from './util/AuthRoute';

//Pages
import home from './pages/home';
import login from './pages/login';
import signup from './pages/signup';
import terms from './pages/terms';

//MUI
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import customTheme from './util/theme';

const theme = createMuiTheme(customTheme);

axios.defaults.baseURL = 'https://europe-west1-zaplify-mmvp.cloudfunctions.net/api';

const token = localStorage.FBIdToken;
if(token) {
  console.log('ok');
  const decodedToken = jwtDecode(token);
  if( decodedToken.exp * 1000 < Date.now() ) {
    store.dispatch(logoutUser());
    window.location.href = '/login';
  } else {
    store.dispatch({type: SET_AUTHENTICATED});
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
            <Router>
              <Navbar />
              <div className="page-wrapper container">
                <Switch>
                  <Route exact path="/" component={home}/>
                  <AuthRoute exact path="/login" component={login}/>
                  <AuthRoute exact path="/signup" component={signup}/>
                  <AuthRoute exact path="/terms" component={terms}/>
                </Switch>
              </div>
            </Router>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;

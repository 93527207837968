import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

//Components
import EditDetails from './EditDetails';

//Redux
import { connect } from 'react-redux';
import { logoutUser } from '../redux/actions/userActions';

//MUI
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import MuiLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

//Icons
import KeyboardReturn from '@material-ui/icons/KeyboardReturn';

const styles = (theme) => ({
    ...theme.custom,
    '.profile-details-heading': {
        margin: theme.spacing(3)
    }
});

class Profile extends Component {
    
    handleLogout = () => {
        this.props.logoutUser();
    }
    render() {
        const {
            classes,
            user: {
                credentials: { handle, createdAt, website, location },
                loading,
                authenticated
            }
        } = this.props;

        let profileMarkup = !loading ? (authenticated ? (
            <div className={classes.profile}>
                <div className="profile-image" >
                </div>
                <div className="profile-details">
                    <div className="profile-details-heading">
                        <MuiLink component={Link} to={`/users/${handle}`} variant="h5">
                            Welcome {handle}
                        </MuiLink>
                        <br/>&nbsp;
                    </div>
                    <EditDetails/>
                </div>
                <Tooltip title="Logout" placement="top">
                    <IconButton onClick={this.handleLogout}>
                        <KeyboardReturn color="primary"></KeyboardReturn>
                    </IconButton>
                </Tooltip>
            </div>
        ) : (
            <div>
                <h2>Welcome to your Zaplify workspace</h2><p>Please login to manage your settings.</p>
                <p>By logging in you agree to our <MuiLink component={Link} to="/terms">terms and privacy policy</MuiLink></p>
                <Button variant="contained" color="primary" component={Link} to="/login">Login</Button>
            </div>
        )) : (<p>loading...</p>);

        return profileMarkup;
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapActionsToProps = { logoutUser };

Profile.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Profile))

import React, { Component, Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

//Redux
import { connect } from 'react-redux';
import { editUserDetails } from '../redux/actions/userActions';

//MUI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import CustomSelect from './molecules/Select';

//Icons
import EmailIcon from '@material-ui/icons/Email';
import { TextField, Button } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.custom
});

export class EditDetails extends Component {
    constructor(props) {
        super(props);
        this.handleFieldChange = this.handleFieldChange.bind(this);
    }
    state = {
        emailTypeAnchorEl: null,
        emailAddr: '',
        emailPass: '',
        location: '',
        open: false,
        selectedIndex: 0,
        emailType: ''
    }

    options = [
        'Choose your email provider',
        'GSuite/Gmail',
        'Outlook / Office 365 / Microsoft Exchange'
    ];

    handleFieldChange(fieldId, value) {
        this.setState({ [fieldId]: value });
    }

    handleOpen = () => {
        this.setState({ open: true });
        this.mapUserDetailsToState(this.props.credentials);
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = () => {
        var userDetails = {
            emailAddr: this.state.emailAddr,
            emailType: this.state.emailType
        };

        if( this.state.emailPass.trim() !== '' && this.state.emailPass != '__dummy') userDetails.emailPass = this.state.emailPass;

        this.props.editUserDetails(userDetails);
        this.handleClose();
    }

    componentDidMount() {
        const { credentials } = this.props;
        this.mapUserDetailsToState(credentials);
    }

    mapUserDetailsToState = (credentials) => {
        this.setState({
            emailAddr: credentials.emailAddr ? credentials.emailAddr : '',
            emailPass: credentials.emailPass ? credentials.emailPass : '',
            emailType: credentials.emailType ? credentials.emailType : ''
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Tooltip title="Edit details" placement="top">
                    <Button onClick={this.handleOpen} className={classes.button} variant="contained" color="primary">
                    <EmailIcon/>&nbsp;Configure email
                    </Button>
                </Tooltip>
                <Dialog
                open={this.state.open}
                onClose={this.handlClose}
                fullWidth
                maxWidth="sm">
                    <DialogTitle>Edit your Details</DialogTitle>
                    <DialogContent>
                        <form>
                            <CustomSelect id="emailType" value={this.state.emailType} onChange={this.handleFieldChange} />
                            <TextField
                                name="emailAddr"
                                type="text"
                                label="Email address"
                                placeholder="Your email"
                                className={classes.textField}
                                value={this.state.emailAddr}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            <TextField
                            name="emailPass"
                            type="password"
                            label="Email password"
                            placeholder="Your email password"
                            className={classes.textField}
                            value={this.state.emailPass}
                            onChange={this.handleChange}
                            fullWidth
                            />
                            
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}

EditDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    editUserDetails: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    credentials: state.user.credentials
});

export default connect(mapStateToProps, { editUserDetails })(withStyles(styles)(EditDetails));

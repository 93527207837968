export default {
    root: {},
    typography: {
        useNextCariants: true,
        fontSize: 16,
        htmlFontSize: 16
    },
    overrides: {
        MuiButton: {
            root: {
              margin: "0 0 1rem",
            }
        }
    },
  palette: {
    primary: {
        //main:     '#0F3155', //Corporate blue faded
        main:     '#1b2b41', //dark nice
      light:    '#C5CAE9',
      dark:     '#303F9F',
      contrast: '#FFFFFF'
    },
    secondary: {
      main:     '#FF5252',
      //main:     '#19857b',
      light:    '#FF5252',
      dark:     '#c50e29',
      contrast: '#757575'
    },
    background: {
      default: '#f7f8fa',
      //default: '#121e2b'
    },
    divider: '#BDBDBD'
  },
    custom: {
        button: {
            position: 'relative'
        },
        form: {
            textAlign: "center"
        },
        pageTitle: {
            
        },
        progress: {
            position: 'absolute',
        },
        textField: {
            margin: "0 auto 0.75rem"
        },
        customError: {
            color: 'red',
            fontSize: '0.9em'
        },
    }
}
import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';

//MUI
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3, 4, 4),
        border: '1px solid #dddddd'
    }
});

class home extends Component {
    
    render() {
        const { classes } = this.props;

        return (
            <Container component="main" maxWidth="lg" className={classes.container}>
                <div>
                    <h1>Privacy, Terms &amp; Conditions</h1>
                </div>
                <Container maxWidth="md" className={classes.container}>
                    <Paper elevation={8} className={classes.paper}>
                        <h2>Privacy</h2>
                        <i>Last Updated: Dec 18, 2019</i>
                        <p>This privacy policy (the "Policy") sets forth the general principles to be applied by Zaplify ("Zaplify LLC") for the use, collection, retention, protection and disclosures of Personal Data, including Personal Data relating to current and prospective clients, employees, and suppliers or otherwise obtained in the operations. All Processing of Personal Data by Zaplify shall be carried out in compliance with the General Data Protection Regulation (GDPR) (EU) 2016/679.
                        Zaplify values your privacy and strive to make our policies clear and transparent to our Clients. This Privacy Policy describes Zaplify’s collection, use, and disclosure of personal data, as well as the rights You have with respect to Your Personal Data. For any questions regarding this Privacy Policy please contact Us at hello@zaplify.com.
                        IF YOU WANT TO ACCESS, EDIT OR OBTAIN YOUR PERSONAL DATA OR DATA COLLECTED ABOUT YOU, PLEASE CONTACT US.
                        If you are interested in and wish to review what personal data Zaplify potential have on you, contact us. If you wish for Zaplify to erase or not share your personal data at all, simply fill out here. If you decide to opt out from any future prospect search, Zaplify will not continue to Process nor disclose your information.
                        </p>
                        <h3>1. SCOPE</h3>
                        <p>Zaplify provides a search service with a built-in email system. The search algorithm relieves relevant contact information on people with the purpose to help our clients to connect with potential prospects over business matters that matter to them. All personal contact information is generated by the algorithm upon each individual request.
                        Please note that Zaplify care deeply about your privacy and how any data is being shared.
                        If you come across a breach of this Privacy Policy or wish to file a complaint on how Zaplify processed your personal data or in any way think your privacy has not been handled in accordance with this Privacy Policy, do not hesitate to contact us directly at hello@zaplify.com.
                        </p>
                        <h3>2. GENERAL</h3>
                        <p>This Privacy Policy outlines Zaplify’s (“Zaplify”, “the Company”, “our”, “us” or similar) practices with respect to generating, collecting, using and disclosing your information when providing our Product or Services . This also constitutes a part of Zaplify’s Terms of Service (collectively, the “Agreement”).
                        Zaplify is a search algorithm. Therefore any personal data provided by the system will always be retrieved from the open web or computationally just-in-time predicted based on pattern recognition. Email addresses are sometimes retrived from 3rd party vendors.
                        Zaplify care deeply about your security and privacy. Zaplify commits to protect the privacy of:</p>
                        <ul>
                            <li>Visitors of Zaplify’s website (the “Site”)
                            </li>
                            <li>Our clients who use the Zaplify search product (“Clients”),
                            </li>
                            <li>Individuals’ contact information, most commonly an email address, title, full name and current employer (“Prospect”), which is collected and obtained in Zaplify’s Search Algorithm.
                            </li>
                        </ul>
                        <p>The term “you” shall refer to Clients or Prospects, as applicable.</p>
                        <p>Zaplify may change its Privacy Policy in Zaplify’s sole discretion. Zaplify encourages Visitors, Clients and Prospects to frequently visit this page and pay attention to any changes to this Privacy Policy.
                        This Privacy Policy will cover information about practices related to:</p>
                        {//Zaplify’s Search Algorithm
                        }
                        What type of information Zaplify collect and why
                        How Zaplify care about and protect your information
                        {//What happens in the case of a Business transfer
                        }
                        How to best get in contact with Zaplify
                        
                        <h3>3. Account Configurations, by channel</h3>
                        <h4>Email</h4>
                        <p>Configuring your email will allow Zaplify to add your email to Zaplify’s built in automations system with Integromat.</p>
                        <p>Zaplify shall not enter your email to collect data:</p>
                        <ul>
                            <li>Email conversations if not sent from Zaplify in order to collect if email has been opened, link clicks, or has been responded.</li>
                            <li>Monitor your inbox or behavior.</li>
                            <li>Collect data previous emails received or sent.</li>
                        </ul>
                        <h4>LinkedIn</h4>
                        <p> Installing the Zaplify chrome extension allows Zaplify only to receive access to your session cookie to perform automated profile visits, personalized connections and messaging access.</p>
                    </Paper>
                </Container>
            </Container>
        );
    }
}

export default withStyles(styles)(home)

import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// MUI stuff
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//Icons
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';


const styles = (theme) => ({
    ...theme.custom,
    root: {
        flexGrow: 1,
      },
      menuButton: {
        margin: theme.spacing(0, 2, 0, 0),
      },
      menuButtonRight: {
        margin: theme.spacing(0, 0, 0, 2),
      },
      title: {
        flexGrow: 1,
      }
});

class Navbar extends Component {

    constructor() {
        super();
        this.state = {
            anchorEl: null
        }
    }

    render() {
        const { classes, authenticated: auth } = this.props;
        const { anchorEl } = this.state;

        /*const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleMenu = event => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };*/

        return (
            /*<AppBar position="fixed">
                <Toolbar>
                    <Button color="inherit" component={Link} to='/'>Home</Button>
                    <Button color="inherit" component={Link} to='/login'>Login</Button>
                    <Button color="inherit" component={Link} to='/signup'>Signup</Button>
                </Toolbar>
            </AppBar>*/
            <AppBar position="sticky">
                <Toolbar>
                    {false &&
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                    }
                    <Typography variant="h6" className={classes.title}>
                        Zaplify
                    </Typography>
                    {auth ? (
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={this.handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    ) : (
                        <Button className={classes.menuButtonRight} color="inherit" component={Link} to='/login'>Login</Button>
                    )}
                </Toolbar>
            </AppBar>
        )
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated
});

Navbar.propTypes = {
    user: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withStyles(styles)(Navbar));

import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

//Redux
import { connect } from 'react-redux';

//Components
import Profile from '../components/Profile';

//MUI
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3, 4, 4),
        border: '1px solid #dddddd'
    }
});

class home extends Component {
    
    render() {
        const { classes } = this.props;

        return (
            <Container component="main" maxWidth="lg" className={classes.container}>
                <div>
                    <h1>My Zaplify</h1>
                </div>
                <Container maxWidth="sm" className={classes.container}>
                    <Paper elevation={8} className={classes.paper}>
                        <Profile></Profile>
                    </Paper>
                </Container>
            </Container>
        );
    }
}

home.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(home))

import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

//Redux
import { connect } from 'react-redux';
import { signupUser } from '../redux/actions/userActions';

//MUI Stuff
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    ...theme.custom,
    container: {
        minHeight: "calc(98vh - 64px)",
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4.5, 5, 5),
        border: '1px solid #dddddd'
    },
    image: {
        margin: theme.spacing(0, 0, 3),
        height: '65px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
})

class signup extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            handle: '',
            errors: {}
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors });
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        const newUserData = {
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            handle: this.state.email
        }
        this.props.signupUser(newUserData, this.props.history);
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const { classes, UI: { loading } } = this.props;
        const { errors } = this.state;
        
        return (
            <Container component="main" maxWidth="xs" className={classes.container}>
                <Paper elevation={8} className={classes.paper}>
                    <img src="icon.png" alt="Zaplify logo" className={classes.image} />
                    <Typography component="h1" variant="h5" gutterBottom>
                        Signup
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                        <TextField
                            autoFocus
                            className={classes.textField}
                            fullWidth
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            id="email"
                            label="Email Address"
                            margin="normal"
                            name="email"
                            onChange={this.handleChange}
                            required
                            type="email"
                            value={this.state.email}
                            //variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            fullWidth
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            id="password"
                            label="Password"
                            margin="normal"
                            name="password"
                            onChange={this.handleChange}
                            required
                            type="password"
                            value={this.state.password}
                            //variant="outlined"
                        />
                        <TextField
                            className={classes.textField}
                            fullWidth
                            helperText={errors.confirmPassword}
                            error={errors.confirmPassword ? true : false}
                            id="confirmPassword"
                            label="Confirm Password"
                            margin="normal"
                            name="confirmPassword"
                            onChange={this.handleChange}
                            required
                            type="password"
                            value={this.state.confirmPassword}
                            //variant="outlined"
                        />
                        {errors.general && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors.general} 
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={loading}
                            size="large"
                        >
                            Signup
                            {loading && (
                            <CircularProgress size={25} className={classes.progress} />)}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                            <Link href="/login" variant="body2">
                                Allready have an account? Login.
                            </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        )
    }
}

signup.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    signupUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
});

export default connect(mapStateToProps, { signupUser } )(withStyles(styles)(signup));